<template>
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{ $t("message.car_information") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="''"
              :loading="loadingButton"
              @c-submit="submit()"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="grid-content bg-purple">
                {{ $t("message.car_number") }}: {{ selectedItem.number }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                {{ $t("message.car_model") }}: {{ selectedItem.model }}
              </div>
            </el-col>

            <el-col :span="6">
              <div class="grid-content bg-purple">
                {{ $t("message.car_color") }}: {{ selectedItem.color }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                {{ $t("message.guest") }}: {{ selectedItem.guest }}
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="timeline-items__right rounded-sm w-100 mt-5 p-4">
          <table class="table-my-code table-bordered" v-loading="loadingButton">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ columns.guest_coming_reason.title }}</th>
                <th scope="col">{{ columns.visit_date.title }}</th>
                <th scope="col">{{ columns.visit_time.title }}</th>
                <th scope="col">{{ columns.left_date.title }}</th>
                <th scope="col">{{ columns.left_time.title }}</th>
                <th scope="col">{{ columns.created_at.title }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(visitor, index) in visitors"
                :key="'visitor+' + index"
              >
                <th scope="row">{{ visitor.id }}</th>
                <td>
                  {{ visitor.staff.name + " " + visitor.staff.last_name }}
                </td>
                <td>{{ visitor.visiting_date }}</td>
                <td>{{ visitor.visiting_time }}</td>
                <td>{{ visitor.left_date }}</td>
                <td>{{ visitor.left_time }}</td>
                <td>{{ visitor.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import drawer from "@/utils/mixins/drawer";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [drawer],
  props: {
    selectedItem: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loadingButton: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      visitors: "guestCar/visitors",
      columns: "visitor/columns",
    }),
  },
  methods: {
    ...mapActions({
      getvisitors: "guestCar/getCarVisitors",
    }),
    afterOpen() {
      this.fetchVisitors();
    },
    fetchVisitors() {
      if (!this.loadingButton) {
        this.loadingButton = true;
        this.getvisitors(this.selectedItem.id)
          .then((res) => {
            this.loadingButton = false;
          })
          .catch((err) => {
            this.loadingButton = false;
          });
      }
    },
  },
};
</script>
