<template>
  <div>
    <el-form ref="form" status-icon :model="form" :rules="rules">
      <el-form-item prop="full_name" size="medium">
        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block" >
          {{ $t("message.full_name") }}
        </span>
        <crm-input
          :inputValue="form.full_name"
          v-model="form.full_name"
          :placeholder="$t('message.full_name')"
          :class="mode ? 'input__day' : 'input__night'"
        ></crm-input>
      </el-form-item>
      <el-form-item prop="gender_id">
        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
          {{ $t("message.gender") }}
        </span>
        <select-gender
          :id="form.gender_id"
          v-model="form.gender_id"
        ></select-gender>
      </el-form-item>
      <el-form-item prop="name" size="medium">
        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
          {{ $t("message.additional_information") }}
        </span>
        <el-input
          type="textarea"
          :placeholder="$t('message.additional_information')"
          v-model="form.additional_information"
          :class="mode ? 'input__day' : 'input__night'"
        >
        </el-input>
      </el-form-item>
      <el-form-item  size="medium">  
        <select-or-hidden-company
        :size="'medium'"
          :id="form.visit_company_id"
          v-model="form.visit_company_id"
        ></select-or-hidden-company>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()" v-loading="loadingButton">
        Сохранить
      </el-button>
      <el-button type="warning" @click="close()"> Закрыть </el-button>
    </span>
  </div>
</template>
<script>
import selectGender from "@/components/filters/inventory/select-gender";
import { i18n } from "@/utils/i18n";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialog],
  components: { selectGender },
  data() {
    return {
      form: {},
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters({
      rules: "guest/rules",
      model: "guest/model",
      columns: "guest/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "guest/store",
      empty: "guest/empty",
      guestInventory: "guest/inventory",
    }),
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.$parent.$parent.form.guest_id=res.data.result.data.guest_id;                  
                this.form = JSON.parse(
                  JSON.stringify({
                    full_name: "",
                    gender_id: null,
                    additional_information: "",
                  })
                );
                this.guestInventory();
                this.$parent.$parent.dialogCreateGuest = false;               
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    close() {
      this.$parent.$parent.dialogCreateGuest = false;
    },
  },
};
</script>
